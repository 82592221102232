import * as d3 from "d3";
import * as url from '@/assets/cone.png';

var path, pathArray = [], isClicking = false, firstClick = true, isfinished = false,
    m1, m2, x, y, lastX, lastY, circle, svg, dragP, dragC, lineFunction, markerDropped = false, marker, dragM, svgstatic, annotationType, uuid;

var d3AnnotationTypeMap = {
    "Zone": "Zone",
    "Marker": "Marker",
    "Line": "Line",
    "Direction": "Direction"
}

function initVariables() {
    path = null, pathArray = [], isClicking = false, firstClick = true, markerDropped = false,
        m1 = null, m2 = null, x, y, lastX = null, lastY = null, circle = null, svg = null, dragP = null, dragC = null, lineFunction = null, marker = null, dragM = null, svgstatic = null;
}

function dragPath(dataSource) {
    path.attr('fill', 'gray');
    var e = d3.event;
    pathArray.forEach(function (datum, index) {
        datum.x += e.dx;
        datum.y += e.dy;
    });
    updatePath();
    updateCircle();
}
function dragCircle(dataSource) {
    var e = d3.event;
    dataSource.x += e.dx;
    dataSource.y += e.dy;
    updatePath();
    updateCircle();

}
function updatePath(finish = false) {
    if (!path) {
        path = svg.append('path').attr('id', uuid + "_path");
        path.attr('class', annotationType);
    }
    else if (finish) {
        pathArray.splice(-1)
    }
    if(finish && annotationType == "Direction"){

            path.remove()

            svg.append("svg:defs")
            .append("svg:marker")
            .attr("id", "arrow")	
            .attr("refX", 8)
            .attr("refY", 5)
            .attr("markerWidth", 12)
            .attr("markerHeight", 12)
            .attr("orient", "auto")
            .attr("fill", "#00a2f2")
            .append("svg:path")
            .attr("d", "M2,2 L2,8 L9,5 L2,2");


            // path = svg.append('path').attr('id', uuid + "_path");
            // path.attr('class', annotationType);

            svg.append("line")
            .attr('id', uuid + "_path")
            .attr('class', annotationType)
             .attr("x1",pathArray[0].x)  
             .attr("y1",pathArray[0].y)  
             .attr("x2",pathArray[1].x)  
             .attr("y2",pathArray[1].y)  
             .attr("stroke","red")  
             .attr("stroke-width",3)  
             .attr("marker-end","url(#arrow)"); 
    }
    else{
        path.attr('d', lineFunction(pathArray)).attr('fill', 'gray');
    }
}

function destroyCanvas(type){
    
    d3.selectAll(".event-annotations").remove();
    if(type=="img"){
        d3.selectAll(".event-annotation-img").remove();
    }
    else if(type=="video"){
        d3.selectAll(".event-annotation-video").remove();
    }
    // d3.selectAll(".event-Zone").remove();
    // d3.selectAll(".event-Line").remove();
    // d3.selectAll(".event-Direction").remove();
    // d3.selectAll(".event-Marker").remove();
}

function drawAnnotations(type, annotations, eventID, zoom = 1, page="rules") {
    console.log(annotations)
    for (let annotation of annotations) {
        initVariables()
        annotationType = d3AnnotationTypeMap[annotation.type]

        let drawingCanvas = ''
        console.log(type)
        if(type==="video"){
            console.log('video')
            drawingCanvas = d3.select(`#video-canvas`)
        }
        else if(type==="img"){            
            console.log('img')
            drawingCanvas = d3.select(`#img-canvas`)
        }

        let imageCanvas = document.getElementById(`event-image`)
        let videoCanvas = document.getElementById(`event-video`)
        let canvas
        if(!imageCanvas && !videoCanvas){
            return
        }else if(!imageCanvas){
            canvas = videoCanvas
        }else{
            canvas = imageCanvas
        }
        let canvasWidth = canvas.getBoundingClientRect().width
        let canvasHeight = canvas.getBoundingClientRect().height
        console.log(drawingCanvas)
        if(type=="img"){
            svg = drawingCanvas
            .append('svg')
            .attr("id", "event-"+annotation.uuid)
            .attr("class", "event-annotation-img")
            .attr({ 'width': canvasWidth })
            .attr({ 'height': canvasHeight })
            .style({ "top": canvas.style.marginTop })
            .style("position", "absolute")
            .style("left", '13.5px');
        }
        else if(type=="video"){
            svg = drawingCanvas
            .append('svg')
            .attr("id", "event-video-"+annotation.uuid)
            .attr("class", "event-annotation-video")
            .attr({ 'width': canvasWidth })
            .attr({ 'height': canvasHeight })
            .style({ "top": canvas.style.marginTop })
            .style("position", "absolute")
            .style("left", '13.5px');
        }
        //For this to work we need to sort out image to make sure top left matches up with canvas
        let width = parseInt(canvas.offsetWidth * zoom)
        let height = parseInt(canvas.offsetHeight * zoom)

        if (annotationType == "Marker") {
            markerDropped = true
            let markerD3 = svg.append("image")
                .attr('x', (annotation.points[0][0] * (width)) - 12)
                .attr('y', (annotation.points[0][1] * (height)) - 24)
                .attr("id", "event_"+annotation.uuid + "_marker")
                .attr("class", "event-"+annotationType)
                .attr('width', 24)
                .attr('height', 24)
                .attr("xlink:href", url.default)
        }
        else if (annotationType == "Direction") {
            lineFunction = d3.svg.line()
                .x(function (d) { return d.x; })
                .y(function (d) { return d.y; })
                .interpolate("linear-closed");
            dragP = d3.behavior.drag().on('drag', dragPath);
            dragC = d3.behavior.drag().on('drag', dragCircle);

            let pts = []
            for (let point of annotation.points) {
                let x = parseInt(point[0] * (width))
                let y = parseInt(point[1] * (height))
                pts.push({ "x": x, "y": y })
            }

            svg.append("svg:defs")
            .append("svg:marker")
            .attr("id", "arrow")	
            .attr("refX", 8)
            .attr("refY", 5)
            .attr("markerWidth", 12)
            .attr("markerHeight", 12)
            .attr("orient", "auto")
            .attr("fill", "#3fcb7a")
            .append("svg:path")
            .attr("d", "M2,2 L2,8 L9,5 L2,2");


            // path = svg.append('path').attr('id', uuid + "_path");
            // path.attr('class', annotationType);

            svg.append("line")
            .attr('id', "event_"+annotation.uuid + "_path")
            .attr('class', "event-"+annotationType)
             .attr("x1",pts[0].x)  
             .attr("y1",pts[0].y)  
             .attr("x2",pts[1].x)  
             .attr("y2",pts[1].y)  
             .attr("stroke","red")  
             .attr("stroke-width",3)  
             .attr("marker-end","url(#arrow)"); 
            
        }

        else {
            lineFunction = d3.svg.line()
                .x(function (d) { return d.x; })
                .y(function (d) { return d.y; })
                .interpolate("linear-closed");
            dragP = d3.behavior.drag().on('drag', dragPath);
            dragC = d3.behavior.drag().on('drag', dragCircle);

            let pts = []
            for (let point of annotation.points) {
                let x = parseInt(point[0] * (width))
                let y = parseInt(point[1] * (height))
                pts.push({ "x": x, "y": y })
            }
            path = svg.append('path').attr('id', "event_"+annotation.uuid + "_path");
            path.attr('class',"event-"+ annotationType);
            path.attr('d', lineFunction(pts)).attr('fill', 'gray');
        }
    }
}


export { drawAnnotations, destroyCanvas }