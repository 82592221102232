
<template>
  <div class="tag-badge" :style="{'margin': customMargin }"  v-b-tooltip.hover.top="showDescription ? description : null" v-if="scope && scope.name">
    <b-badge class="custom-b-badge-overwrite-left" :id="id+'-custom-b-badge-overwrite-left'" :style="{'background-color': badgeColor, 'outline': `1px solid ${badgeColor}`}" pill>
        {{ scope.name }}
    </b-badge>
    <b-badge class="custom-b-badge-overwrite-right" :style="{'outline': `1px solid ${badgeColor}`}" pill>
        {{ tagName }}
        <feather-icon v-if="hasClose"
          badge-classes="bg-danger"
          class="tag-close-icon"
          icon="XIcon"
          size="21"
          v-b-hover="handleHover"
          :style="isCloseHovered ? {'background-color': `${badgeColor}`, 'color': 'white', 'border-radius': '50%'} : {}"
          v-on:click.prevent
          @click="removeTag()"
        />
    </b-badge>
  </div>
  <div class="tag-badge" :style="{'margin': customMargin }" v-else>
    <b-badge v-b-tooltip.hover.top="showDescription ? description : null" :style="{'background': badgeColor}" pill>
        {{tagName}}
         <feather-icon v-if="hasClose"
          badge-classes="bg-danger"
          class="tag-close-icon"
          icon="XIcon"
          size="21"
          v-on:click.prevent
          @click="removeTag()"
        />
    </b-badge>
  </div>
</template>
<script>
import { BAvatar, BBadge, VBTooltip, VBHover } from 'bootstrap-vue'
import EventBus from "@/event-bus.js";

export default {
  components: {
    BAvatar,
    BBadge,
    VBTooltip,
    VBHover
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-hover": VBHover
  },
  data(){
    return{
      isCloseHovered: false,
      badgeColor: this.color,
      id: null
    }
  },
  mounted(){
      this.id = this._uid
      if(this.color==null){
        this.badgeColor = "#00a0f2"
      }
      else{
        this.badgeColor = this.color
      }
      this.$nextTick(() => {
        if(this.hasClose){
          document.getElementById(this.id+"-custom-b-badge-overwrite-left").style.paddingBottom = "4.5px"
        }
      })
  },
  watch: {
    color(newVal){
      this.badgeColor = newVal
    }
  },
  methods: {
    removeTag(){
      EventBus.$emit("remove-tag-from-list", this.id, this.tagName)
    },
    handleHover(){
      this.isCloseHovered = !this.isCloseHovered
    }
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: Number,
      default: null,
    },
    scope: {
      type: Object,
      default: null,
    },
    color: {
        type: String,
        default: "#00a0f2"
    },
    tagName: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    customMargin: {
      type: String,
      default: '4px',
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-b-badge-overwrite-left {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 6px;
    border-radius: 0;
    border-bottom-left-radius: 10rem;
    border-top-left-radius: 10rem;
}

.custom-b-badge-overwrite-right {
    border-radius: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px;
    border-bottom-right-radius: 10rem;
    border-top-right-radius: 10rem;
    background-color: white;
    color: black;
}
.tag-badge {
    cursor: pointer;
    display: inline-block;
}
.tag-close-icon {
  margin-left: 2px;
  padding-top:1px;
  padding-bottom:1px;
}

</style>
