<template>
    <div>
        <b-row style="height: 80vh ; margin-top: -13px ">
            <b-col :cols="leftCols">
                <font-awesome-icon
                    v-if="sidebarOpen"
                    @click="closeSidebar()"
                    icon="fa-chevrons-right"
                    style="
                        cursor: pointer;
                        font-size: 20px;
                        position: absolute;
                        right: 16px;
                        top: 4px;
                    "
                />
                <font-awesome-icon
                    v-if="!sidebarOpen"
                    @click="openSidebar()"
                    icon="fa-chevrons-left"
                    style="
                        cursor: pointer;
                        font-size: 20px;
                        position: absolute;
                        right: 16px;
                        top: 4px;
                    "
                />

                <div v-if="selectedSite == null">
                    <center style="margin-top: 5rem">
                        <h3>
                            Please Select A Site
                            <font-awesome-icon
                                icon="fa-arrow-right"
                                style="
                                    font-size: 24px;
                                    color: #00a0f2;
                                    margin-left: 8px;
                                "
                            />
                        </h3>
                    </center>
                </div>
                <div v-if="selectedSite != null && this.batchFinished">
                    <center style="margin-top: 5rem">
                        <b-button
                            @click="getEventList(selectedSite)"
                            style="
                                padding-right: 16px;
                                padding-left: 16px;
                                margin-left: 20px;
                                margin-right: 20px;
                            "
                            ><font-awesome-icon
                                class=""
                                icon="fa-check"
                                style="font-size: 18px; color: white"
                            /><span
                                style="
                                    font-weight: 600;
                                    font-size: 18px;
                                    margin-left: 8px;
                                "
                                >Load Next Batch</span
                            ></b-button
                        >
                    </center>
                </div>
                <div v-if="selectedSite != null && this.event_list.length < 1 && !loadingBatch">
                    <center style="margin-top: 5rem">
                      <b-badge variant="warning">
                        <font-awesome-icon
                                class=""
                                icon="fa-x"
                                style="font-size: 30px; color: white; margin-top: 3px;"
                            /><span
                                style="
                                    font-weight: 600;
                                    font-size: 18px;
                                    margin-left: 8px;
                                "
                                >No events - please choose another site</span
                            >
                          </b-badge>
                    </center>
                </div>
                <div v-if="selectedSite != null && !this.batchFinished && event_list.length > 0 && event_list[cursor].id">
                    <b-row
                        style="
                            margin-right: 0px;
                            margin-right: 0px;
                            margin-left: 0px;
                            text-align: center;
                            margin-bottom: 2rem;
                        "
                    >
                        <b-row  v-if="!hideVideo"  style="width: 100%; margin-left: 20px; margin-right: 24px;">
                                <p style="font-size: 16px;width: 100%;font-weight: 500; text-align: left" v-if="event_list.length && event_list[cursor].rule && event_list[cursor].rule.rules_cameras.length">
                                    <span  style="font-weight: 700; display: inline-block; color: #00a0f2" >Rule Definition : </span> {{getRuleDescriptionByCamera(event_list[cursor].rule.rules_cameras)}}
                                </p>
                                <p  style="font-size: 16px;width: 100%;font-weight: 500; text-align: left" v-else >
                                   <span style="font-weight: 700; display: inline-block; color: #00a0f2"> Rule Definition: </span> no definition available
                                </p>
                        </b-row>
                    </b-row>

                    <b-row
                       :class="sidebarOpen=== false ? 'center-align-video': null"
                        style="
                            margin-right: 0px;
                            margin-right: 0px;
                            margin-left: 0px;
                            margin-bottom: 0rem;
                        "
                    >
                        <b-col :cols="12-widthOnOpeningSidebar" v-if="!hideVideo">
                            <div style="text-align: center ; margin-top: -22px" id="video-canvas" >
                                <h3 v-if="event_list[cursor].video_url==null || eventVideoMap[event_list[cursor].video_url] === undefined"> Video Unavailable <b>Mark as <u>Not Sure</u></b></h3>
                                <video
                                    v-if="event_list[cursor].video_url!=null && eventVideoMap[event_list[cursor].video_url] !== undefined"
                                    id="event-video"
                                    type="video/mp4"
                                    nt="video"
                                    width="100%"
                                    height="auto"
                                    controls
                                    controlsList="nodownload"
                                    autoplay
                                    loop
                                    style="
                                        border-radius: 6px;
                                        width: 100%;
                                        height: auto;
                                    "
                                    :src="
                                        event_list.length != 0 &&
                                        eventVideoMap[
                                            event_list[cursor].video_url
                                        ]
                                            ? eventVideoMap[
                                                  event_list[cursor].video_url
                                              ]
                                            : null
                                    "
                                     @loadedmetadata="cursor === 0 ? drawOnImage('video') : null"
                                />
                                <span
                                v-if="event_list[cursor].video_url!=null && eventVideoMap[event_list[cursor].video_url] !== undefined"
                                    style="
                                        position: absolute;
                                        top: 45%;
                                        right: 20%;
                                        color: rgba(255, 255, 255, 0.4);
                                        font-weight: 200;
                                        font-size: 28px;
                                        transform: rotate(-30deg);
                                    "
                                    >{{ getUserName() }} -
                                    {{ getUserEmail() }}</span
                                >
                            </div>
                            <b-row>
                                <b-col cols="8">
                                    <b-row>
                                        <b-col cols="2"> 
                                            <b-form-checkbox
                            v-if="event_list[cursor].video_url!=null && eventVideoMap[event_list[cursor].video_url] !== undefined"
                                style="margin-top: 2px"
                                @change="
                                    isVideoAnnotatedToggle
                                        ? drawOnImage('video')
                                        : undrawOnImage('video')
                                "
                                class="custom-control-primary"
                                name="check-button"
                                switch
                                v-model="isVideoAnnotatedToggle"
                            >
                                <span class="switch-icon-left">
                                    <feather-icon icon="EyeIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="EyeOffIcon" />
                                </span>
                            </b-form-checkbox>
                                        </b-col>
                                        <b-col v-if="event_list[cursor]" style="text-align: end;"> 
                                            <p style=" display: inline-block;font-size: 15px;margin-top: 5px; font-weight: 500;">#{{ event_list[cursor].id }} ({{ getDate }})</p>
                                        </b-col>
                                    </b-row>                             
</b-col>
<b-col>

                            <b-form-group
                            v-if="event_list[cursor].video_url!=null && eventVideoMap[event_list[cursor].video_url] !== undefined"
                                v-slot="{ ariaDescribedby }"
                                style="float:right"
                                >
                                <b-form-checkbox-group
                                    v-model="selected"
                                    :options="options"
                                    @click.native="speedSelected"
                                    :aria-describedby="ariaDescribedby"
                                    buttons
                                    button-variant="outline-primary"
                                    size="sm"
                                    name="buttons-2"
                                ></b-form-checkbox-group>
                                </b-form-group>
</b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                     <b-row style="margin-left: 20px; margin-bottom: 20px;">
                                           
                                            <p style="font-size: 16px; font-weight: 500; text-align: left;">
                                        <span  style="display: inline-block;  font-weight: 700;color: #00a0f2">{{
                                        event_list.length &&
                                        event_list[cursor].rule &&
                                        event_list[cursor].rule.name
                                    }}: </span>
                                        
                                    
                                    {{
                                        event_list.length &&
                                        event_list[cursor].rule &&
                                        event_list[cursor].rule.description
                                    }}
                                    
                                    
                                        {{
                                        event_list.length &&
                                        event_list[cursor].rule &&
                                        event_list[cursor].rule.rules_cameras
                                            .length &&
                                        event_list[cursor].rule.rules_cameras[0]
                                            .qa_description
                                    }}
                                    </p>
                                    <p style="font-size: 16px;width: 100%;font-weight: 500; text-align: left;margin-top: -6px " v-if = "ruleBlocks && ruleBlocks.length">
                                    <span style="font-weight: 700; display: inline-block; color: #00a0f2" >Rule Blocks: </span>
                                    <span v-for="block, i in ruleBlocks" v-bind:key="block">
                                    {{ block }} <span v-if="i!=ruleBlocks.length-1">-</span>
                                   </span>
                                </p>
                                <p  style="font-size: 16px;width: 100%;font-weight: 500; text-align: left;margin-top: -6px " v-else>
                                    <span style="font-weight: 700; display: inline-block; color: #00a0f2" >Rule Blocks: </span> No blocks available
                                </p>
                    </b-row> 
                    <b-row
                        style="
                            width: 100%;
                            text-align: center;
                            margin-right: 0px !important;
                            margin-left: 0px !important;
                        "
                    >
                        <div style="margin: auto">
                            <b-button
                                @click="
                                    approveEvent(event_list[cursor].violation_uuid, 'approved')
                                "
                                style="
                                    padding-right: 16px;
                                    padding-left: 16px;
                                    margin-left: 20px;
                                    margin-right: 20px;
                                "
                                ><font-awesome-icon
                                    class=""
                                    icon="fa-check"
                                    style="font-size: 18px; color: white"
                                /><span
                                    style="
                                        font-weight: 600;
                                        font-size: 18px;
                                        margin-left: 8px;
                                    "
                                    >Approve [a]</span
                                ></b-button
                            >
                            <b-button
                                @click=" approveEvent(event_list[cursor].violation_uuid, 'interesting')"
                                variant="primary"
                                style="
                                    padding-right: 20px;
                                    padding-left: 20px;
                                    margin-left: 20px;
                                    margin-right: 20px;
                                "
                                ><font-awesome-icon
                                    class=""
                                    icon="fa-exclamation"
                                    style="font-size: 18px; color: white"
                                /><span
                                    style="
                                        font-weight: 600;
                                        font-size: 18px;
                                        margin-left: 8px;
                                    "
                                    >Interesting [i]</span
                                ></b-button
                            >

                            <b-button
                                @click="
                                    approveEvent(event_list[cursor].violation_uuid, 'false_positive')
                                "
                                variant="danger"
                                style="
                                    padding-right: 16px;
                                    padding-left: 16px;
                                    margin-left: 20px;
                                    margin-right: 20px;
                                "
                                ><span
                                    style="
                                        font-weight: 600;
                                        font-size: 18px;
                                        margin-left: 8px;
                                    "
                                    >False Positive [f]</span
                                ></b-button
                            >

                            <b-button
                                @click=" approveEvent(event_list[cursor].violation_uuid, 'not_sure')"
                                variant="dark"
                                style="
                                    padding-right: 20px;
                                    padding-left: 20px;
                                    margin-left: 20px;
                                    margin-right: 20px;
                                "
                                ><span
                                    style="
                                        font-weight: 600;
                                        font-size: 18px;
                                        margin-left: 8px;
                                    "
                                    >Video Quality [v]</span
                                ></b-button
                            >
                        </div>
                    </b-row>
                    <br />
                    <center>
                        <b-form-textarea
                            @blur="toggleKeyboadShortcut('enable')"
                            @focus="toggleKeyboadShortcut('disable')"
                            id="comments"
                            style="width: 520px"
                            rows="1"
                            placeholder="Write a comment on the event"
                            v-model="comments"
                            name="comments"
                        />
                    </center>
                </div>
            </b-col>
            <b-col
                v-if="sidebarOpen"
                :cols="rightCols"
                style="
                    height:95vh;
                    padding-right: 0px;
                    border-left: solid 1px #e2e6e9;
                    text-align: center;
                    overflow: scroll;
                "
            >
                <div style="margin: auto">
                    <div
                        style="
                            text-align: left;
                            margin-bottom: 16px;
                            font-size: 20px;
                            font-weight: 700;
                            border-bottom: solid 1px #e2e6e9;
                            padding-bottom: 10px;
                            margin-left: -14px;
                        "
                    >
                        <span style="padding-left: 14px; width: 100%">
                            QA Workers Online
                        </span>
                        <div
                            style="
                                border-top: 1px solid rgb(226, 230, 233);
                                margin-top: 10px;
                                margin-bottom: 14px;
                            "
                        >
                            <div
                                style="
                                    margin-left: 14px;
                                    margin-top: 14px;
                                    font-size: 14px;
                                    font-weight: 400;
                                "
                            >
                            <div v-if="'data' in usersOnline">
                                <div
                                v-for="user in usersOnline.data.users"
                                :key="user.user_id">
                                <div style="" v-if="(user.email != getUserEmail()) && checkSameCompany(user.email, getUserEmail())">
                                    {{user.first_name}} {{user.last_name}}:<b> {{user.email}}</b>
                                </div>
                                    </div>
                            </div>

                            </div>
                        </div>

                        <span style="padding-left: 14px; width: 100%">
                            Summary
                        </span>
                        <div
                            style="
                                margin-top: 10px;
                                margin-bottom: 10px;
                                border-bottom: 1px solid rgb(226, 230, 233);
                            "
                        ></div>

                        <div
                            style="
                                margin-left: 14px;
                                font-size: 14px;
                                font-weight: 400;
                                margin-bottom: 14px;
                            "
                        >
                            <div>
                                Current Site:<b> {{ selectedSiteName }} </b>
                            </div>
                            <div>
                                Events QA'd in this Session:<b>
                                    {{ totalEventsQAd }}</b
                                >
                            </div>
                            <div>
                                <span >Site-Specific Interesting Events This Week ({{ startDate }} - {{ endDate }}):
                                <b>  {{ markedAsInteresting}} </b></span>
                            </div>
                            <div>
                                Avg Time per Event:
                                <b>{{ avgTimePerEvent }} secs</b>
                            </div>
                        </div>

                        <span style="padding-left: 14px; width: 100%">
                            Progress
                        </span>
                        <div
                            style="
                                border-top: 1px solid rgb(226, 230, 233);
                                margin-top: 10px;
                            "
                        >
                            <div style="margin-left: 14px">
                                <div
                                    style="
                                        font-weight: 500;
                                        font-size: 16px;
                                        margin-bottom: 4px;
                                        margin-top: 10px;
                                    "
                                >
                                    Overall Progress
                                </div>
                                <b-progress
                                    height="16px"
                                    style="margin-bottom: 14px"
                                    :max="100"
                                    :variant="getProgressColor((totalEventsQAd/totalEventsToQa)*
                                            100)"
                                    show-progress
                                    :precision="2"
                                >
                                    <b-progress-bar
                                        :value="(totalEventsQAd/totalEventsToQa)*
                                            100"
                                        :label="`${(totalEventsQAd/totalEventsToQa)*
                                            100}%`"
                                    ></b-progress-bar>
                                </b-progress>
                                <div
                                    style="
                                        font-weight: 500;
                                        font-size: 16px;
                                        margin-bottom: 4px;
                                    "
                                >
                                    Current Site Progress
                                </div>
                                <b-progress
                                    height="16px"
                                    style="margin-bottom: 14px"
                                    :max="100"
                                    :variant="getProgressColor((totalSiteEventsQAd/totalSiteEventsToQa)*
                                            100)"
                                    show-progress
                                    :precision="2"
                                >
                                    <b-progress-bar
                                        :value="(totalSiteEventsQAd/totalSiteEventsToQa)*
                                            100"
                                        :label="`${(totalSiteEventsQAd/totalSiteEventsToQa)*
                                            100}%`"
                                    ></b-progress-bar>
                                </b-progress>
                                <div
                                    style="
                                        font-weight: 500;
                                        font-size: 16px;
                                        margin-bottom: 4px;
                                    "
                                >
                                    Batch Progress ({{this.cursor}}/{{
                                                this.event_list.length}})
                                </div>
                                <b-progress
                                    height="16px"
                                    style="margin-bottom: 18px"
                                    :max="100"
                                    show-progress
                                    :variant="
                                        getProgressColor(
                                            (this.cursor /
                                                this.event_list.length) *
                                                100
                                        )
                                    "
                                >
                                    <b-progress-bar
                                        :value="
                                            parseInt((this.cursor /
                                                this.event_list.length) *
                                            100)
                                        "
                                        :label="`${
                                            parseInt((this.cursor /
                                                this.event_list.length) *
                                            100)
                                        }%`"
                                    ></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                       <div style="display: flex;"> 
                        <span style="padding-left: 14px"> Sites </span>
                        <div style="margin-left: 15px;display: flex; margin-top: 2px; font-size: 13px; font-weight: 500; flex-direction: row; justify-content: flex-end;width: 100%; margin-right: 5px;">
                           <span style="margin-top: 5px; margin-right: 5px;"> Sort by: </span>
                            <v-select 
                            id="site-sort-dropdown"
                            style="font-size: 13px; font-weight: 500;"
                            :options = "sortOptions"
                            v-model="selectedSortOption"
                            :clearable="false"
                            /> 
                        </div>
                       </div>
                    </div>
                    <div v-if="site_review_map.length === 0 || loadingBatch">
                        <div>
                            <font-awesome-icon
                                icon="fa-duotone fa-rotate"
                                class="fa-loader"
                                style="font-size: 20px"
                            />
                        </div>
                        <div>Loading...</div>
                    </div>
                    <div  v-else > 
                        <div 
                        v-for="sites in site_review_map"
                        :key="sites.id"
                        class='site-block'
                        style="
                            padding-top: 5px;
                            padding-bottom: 5px;
                            margin-bottom: 10px;
                            text-align: left;
                            padding-left: 12px;
                            font-weight: 500;
                            font-size: 16px;
                        "
                        v-bind:style="
                            selectedSite == sites.id && !loadingBatch
                                ? 'transform: scale(1.01); background: #00a0f2; color:white'
                                : ''
                        "
                        @click="if(selectedSite != sites.id)getEventList(sites.id);"
                    >
                        <div
                            v-if="!(loadingBatch && sites.id === selectedSite)"
                        >
                            {{ sites.site_name }}
                            <br />
                            <span style="font-size: 12px; font-weight: 400"
                                >Events Available:</span
                            >
                            <span style="font-size: 12px; font-weight: 400">
                                {{ sites.count }}
                            </span>
                        </div>
                        <div v-if="loadingBatch && sites.id === selectedSite">
                            <div>
                                <font-awesome-icon
                                    icon="fa-duotone fa-rotate"
                                    class="fa-loader"
                                    style="font-size: 16px"
                                />
                            </div>
                            <div>Loading Events Batch...</div>
                        </div>
                    </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BProgress,
    BProgressBar,
    BFormCheckboxGroup,
    BFormGroup,
    BBadge
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import qaApiService from "@core/utils/qa-api-service/qaApiService.js";
import Tag from "@core/components/tags/Tag.vue";
import axios from "axios";
import { drawAnnotations, destroyCanvas } from "./drawing-utils";
import moment from "moment-timezone";
import getRuleDescription from "./getRuleDescription.js";
import {
    getUserRole,
    getUserSiteId,
    getUserId,
    getUserName,
    getUserProfileImage,
    getUserEmail,
} from "@/auth/utils";

const API_ENDPOINT_PRESIGNED_URL = process.env.VUE_APP_LAMBDA_PRESIGNED_URL;

export default {
    components: {
        BRow,
        BProgress,
        BCol,
        BButton,
        BProgressBar,
        BFormCheckbox,
        BFormTextarea,
        BFormCheckboxGroup,
        BFormGroup,
        BBadge,
        vSelect,
        Tag
    },

    data() {
        return {
            imgColWidth: 6,
            hideVideo: false,
            ruleBlocksList: [],
            selected: ["normal"], // Must be an array reference!
        options: [
          { text: '0.5x', value: 'slow' },
          { text: '1x', value: 'normal' },
          { text: '2x', value: 'fast' },
        ],
            totalSiteEventsQAd: 0,
            totalSiteEventsToQa: 0,
            totalEventsToQa: 0,
            initialLoadSession: true,
            isVideoAnnotatedToggle: false,
            isImgAnnotatedToggle: false,
            comments: "",
            batchFinished: false,
            startTimer: false,
            startTime: null,
            endTime: null,
            avgTimePerEvent: 0,
            timePerEvent:0,
            loadingBatch: false,
            guardAction: false,
            sidebarOpen: true,
            selectedSortOption: "None",
            sortOptions:["Event count", "None"],
            unsortedSites:[],
            widthOnOpeningSidebar:0,
            leftCols: 9,
            rightCols: 3,
            selectedSite: null,
            selectedSiteName: "None Selected",
            totalEventsQAd: 0,
            startDate: null,
            endDate: null,
            markedAsInteresting: 0,
            site_review_map: [],
            event_list: [],
            cursor: 0,
            startOfWeek: " ", 
            endOfWeek: " ",
            eventImageMap: {},
            eventVideoMap: {},
            selectedSiteTimeZone: " ",
            batch_size: 10,
            isImgAnnotated: false,
            isVideoAnnotated: false,
            usersOnline : {},
            keyboardShortcutFlag : true
        };
    },
    async created() {
        await this.getSiteInfo();
        await this.getUsersOnline();
    },
    beforeDestroy() {
        clearInterval(this.sessionUpdateApi);
    },
    mounted() {
        this.startDate = moment().startOf("isoWeek").format("YYYY/MM/DD") //Monday
        this.endDate =moment().endOf("isoWeek").format("YYYY/MM/DD") //Sunday
        setInterval(() => {
            if (this.startTimer) {
                this.endTime = new Date();
                let timeDiff = this.endTime - this.startTime; //in ms
                timeDiff /= 1000;
                this.timePerEvent = Math.round(timeDiff);
                if (this.totalEventsQAd != 0) {
                    this.avgTimePerEvent = Math.round(
                        timeDiff / this.totalEventsQAd
                    );
                } else {
                    this.avgTimePerEvent = Math.round(timeDiff);
                }
            }
        }, 1000);
        this.sessionUpdateApi = setInterval(async () => {
            await this.sendUpdateOnEventsInReview();
        }, 30000);
        this.eventCountUpdateApi = setInterval(async () => {
            await this.getSiteInfo();

        }, 10000);
        this.userSessionUpdateApi = setInterval(async () => {
            await this.sendUpdateOnUser()
        }, 10000);

        this.getUsersOnlineApi = setInterval(async () => {
            await this.getUsersOnline();
            console.log("users-online",this.usersOnline)
        }, 10000);

        document.addEventListener("keydown", (event) => {
            if(this.keyboardShortcutFlag){
                if (event.code == "KeyV") {
                // NOT SURE
                this.approveEvent(this.event_list[this.cursor].violation_uuid, "not_sure")
            }
            if (event.code == "KeyA") {
                // Accept
                this.approveEvent(this.event_list[this.cursor].violation_uuid, "approved");
            }
            if (event.code == "KeyF") {
                // FALSE POSITIVE
                this.approveEvent(this.event_list[this.cursor].violation_uuid, "false_positive");
            }
            if (event.code == "KeyI") {
                // INTERESTING EVENT
                this.approveEvent(this.event_list[this.cursor].violation_uuid, "interesting");
            }
            }

        });
        // update canvas using a debouncer when window size is changed
        window.addEventListener("resize",(event)=>{
            let timer = 600
            setTimeout(()=>{
                this.refetchCanvas();
            },timer)

        })

    },
    setup() {
        return {
            getUserRole,
            getUserSiteId,
            getUserId,
            getUserName,
            getUserProfileImage,
            getRuleDescription,
            getUserEmail,
        };
    },
    watch: {
        event_list(newVal) {
            if (newVal && newVal.length > 0) {
                this.isVideoAnnotatedToggle = true
            }
        },
        selectedSortOption(newVal){
            this.sortSites(newVal)
        }
    },
    methods: {
        // getRuleDescription(rules){
        //     console.log("rules", rules)
        //     //call getRuleDescription.js function here
        //     // return "rule description"


        // },
        sortSites(selectedOption) {
            if (selectedOption === 'Event count') {
                this.site_review_map.sort((a, b) => Number(b.count) - Number(a.count)); 
            }else{
                this.site_review_map = [...this.unsortedSites]
            }
        },
        toggleEnlargeView(){
            this.hideVideo = !this.hideVideo
            if(this.hideVideo){
                this.imgColWidth = 12
            }
            else{
               this.imgColWidth = 6
            }
        },
        checkSameCompany(email1, email2){
            return email1.split('@')[1] == email2.split('@')[1];
        },
        toggleKeyboadShortcut(toggle){
            this.keyboardShortcutFlag = toggle === "enable" ? true:false
        },
        speedSelected(){

            setTimeout(() => {
                if(this.selected.length>1){
                    this.selected = [this.selected[this.selected.length-1]]
                    switch(this.selected[0]){
                        case "slow":
                            document.querySelector('video').playbackRate = 0.5;
                            break
                        case "normal":
                            document.querySelector('video').playbackRate = 1.0;
                            break
                        case "fast":
                            document.querySelector('video').playbackRate = 2.0;
                            break
                        case "very fast":
                            document.querySelector('video').playbackRate = 5.0;
                            break
                        case "very very fast":
                            document.querySelector('video').playbackRate = 10.0;
                            break

                    }
                }
            }, 50);
            // this.selected = this.selected[this.selected.length-1]
            // console.log("AFTER",this.selected)
        },
        getProgressColor(val) {
            if (val >= 0 && val < 30) {
                return "danger";
            } else if (val >= 30 && val < 60) {
                return "warning";
            } else if (val >= 60) {
                return "success";
            }
        },
        drawOnImage(type, camID, ruleID, eventID) {

            if (
                this.event_list[this.cursor]?.rule
            ) {
                let canvas = this.event_list[this.cursor].rule.rules_cameras.find((cam_id) => {
                    return cam_id.camera_id == this.event_list[this.cursor].cam_id;
                });

                drawAnnotations(
                    type,
                    canvas.canvas_meta.annotations,
                    eventID,
                    1,
                    "events"
                );
            }
            if (type === "img") {
                this.isImgAnnotated = true;
            } else if (type === "video") {
                this.isVideoAnnotatedToggle = true;
            }
        },
        undrawOnImage(type) {
            destroyCanvas(type);
            if (type === "img") {
                this.isImgAnnotated = false;
            } else if (type === "video") {
                this.isVideoAnnotatedToggle = false;
            }
        },
        openSidebar() {
            console.clear()
            console.log("open")
            this.leftCols = 9;
            this.rightCols = 3;
            this.sidebarOpen = true;
            this.widthOnOpeningSidebar = 0
            this.refetchCanvas();
        },
        closeSidebar() {
            console.clear()
            console.log("close")
            this.leftCols = 12;
            this.rightCols = 0;
            this.sidebarOpen = false;
            this.widthOnOpeningSidebar = 3
            this.refetchCanvas();
        },
        next() {
            this.comments = "";
            if (this.event_list.length - 1 == this.cursor) {
                this.cursor += 1;
                this.batchFinished = true;
            } else if (this.event_list.length > this.cursor + 1) {
                this.cursor += 1;
            }
            if (this.isImgAnnotated) {
                this.undrawOnImage("img");
                this.drawOnImage("img");
            }
            if (this.isVideoAnnotatedToggle) {
                this.undrawOnImage("video");
                this.drawOnImage("video");
            }
            setTimeout(() => {
                  switch(this.selected[0]){
                        case "slow":
                            document.querySelector('video').playbackRate = 0.5;
                            break
                        case "normal":
                            document.querySelector('video').playbackRate = 1.0;
                            break
                        case "fast":
                            document.querySelector('video').playbackRate = 2.0;
                            break
                        case "very fast":
                            document.querySelector('video').playbackRate = 5.0;
                            break
                        case "very very fast":
                            document.querySelector('video').playbackRate = 10.0;
                            break

                    }
            }, 50);
        },
        prev() {
            if (this.cursor > 0) {
                this.cursor -= 1;
            }
        },
        async markEventAsInteresting(event_id) {
            /**
             *
             * Function approves an event, unstages an event and marks the event as interesting
             */
            let user = getUserId();
            let userName = getUserName();
            let response = ""
            if (!this.guardAction) {
                this.guardAction = true;
                response = await qaApiService("PUT", `events/interesting/${event_id}`, {
                    approved: true,
                    },{ comment: this.comments, user_id: user,  time_taken_per_event: this.timePerEvent, user_name: userName });

                this.totalEventsQAd++;
                this.totalSiteEventsQAd++;
                this.site_review_map[
                    this.site_review_map.findIndex(
                        (x) => parseInt(x.id) === this.selectedSite
                    )
                ].count--;
                this.next();
                setTimeout(() => {
                    this.guardAction = false;
                }, 1000);
            }
        },
        async approveEvent(event_id, approval_status) {
            /**
             *
             * Function approves an event, unstages an event and
             */
            try {
                let user = getUserId();
                let userName = getUserName();
                let response = "";
                if (!this.guardAction) {
                    this.guardAction = true;
                    let selectedSiteRegion =  this.site_review_map[
                        this.site_review_map.findIndex((x) => parseInt(x.id) === this.selectedSite)
                    ].region;
                    response = await qaApiService("PUT", `events/${event_id}`, {
                        approved: approval_status,
                    }, {
                        approval_status: approval_status,
                        qa_comment: this.comments,
                        time_taken_per_event: this.timePerEvent,
                        region: selectedSiteRegion
                    });
                    this.totalEventsQAd++;
                    this.totalSiteEventsQAd++;
                    this.site_review_map[
                        this.site_review_map.findIndex(
                            (x) => parseInt(x.id) === this.selectedSite
                        )
                    ].count--;
                    this.next();
                    setTimeout(() => {
                        if(approval_status === 'interesting') {
                            this.getEventsMarkedInterestingCount();
                        }
                        this.guardAction = false;
                    }, 1000);
                }
            } catch (error) {
                console.error("Error occurred while approving event:", error);
            }
        },
        async getSiteInfo(type) {
            /**
             * Function gets all stagedSites and is run on setup populates the right side of the screen
             */

            let response = [];
            if (type === "cached") {
                response = await qaApiService("GET", "sites/cachedCount");
            } else {
                response = await qaApiService("GET", "sites");
            }
            console.log("data", response.data)
            this.site_review_map = response.data;
            this.site_review_map = this.site_review_map.filter((sites)=> sites.count !== 0)
            this.unsortedSites = this.site_review_map
            this.sortSites(this.selectedSortOption)
            if(this.initialLoadSession){
                this.initialLoadSession = false
                for (var i = 0; i< this.site_review_map.length; i++)
                {
                    this.totalEventsToQa += this.site_review_map[i].count
                }
            }
        },
        async getEventList(site_id) {
            /**
             * Function gets staged events for a site and updates the event_list array
             */
            // send signal if we did a site switch and already had events there
            this.loadingBatch=true
            site_id = parseInt(site_id)
            if (this.selectedSite != site_id) {
                this.isImgAnnotated = false;
                this.isVideoAnnotated = false;
                this.isImgAnnotatedToggle = false;
                this.isVideoAnnotatedToggle = false;
                this.undrawOnImage("img");
                this.undrawOnImage("video");
              

                this.totalSiteEventsQAd = 0
                this.totalSiteEventsToQa = this.site_review_map[
                    this.site_review_map.findIndex(
                        (x) => parseInt(x.id) === site_id
                    )
                ].count;

            }
            this.selectedSite = parseInt(site_id);
            this.batchFinished = false;
            this.cursor = 0;
            console.log("TRUE");
            //this.loadingBatch = true;

            this.selectedSiteName =
                this.site_review_map[
                    this.site_review_map.findIndex((x) => parseInt(x.id) === site_id)
                ].site_name;
            this.selectedSiteTimeZone =
                this.site_review_map[
                    this.site_review_map.findIndex((x) => parseInt(x.id) === site_id)
                ].time_zone;
            let selectedSiteRegion = this.site_review_map[
                this.site_review_map.findIndex((x) => parseInt(x.id) === site_id)
            ].region
            let selectedSiteUUID = this.site_review_map[
                this.site_review_map.findIndex((x) => parseInt(x.id) === site_id)
            ].site_uuid
            if (site_id !== this.selectedSite && this.event_list.length > 0) {
                this.flushEventSession();
            }

            this.event_list = [];
            while (this.event_list.length > 0) {
                this.event_list.pop();
            }
            let response = await qaApiService("GET", "sites/" + selectedSiteRegion + "/" + selectedSiteUUID);
            response.data.data.violations.map((event) => {
                this.event_list.push(event);
            });
            if (this.event_list.length < 1){
              this.loadingBatch = false;
            }
            this.getEventsMarkedInterestingCount()
            //console.log("FALSE")
            //this.loadingBatch = false;
            await this.loadEventAssets(site_id);

            if (this.startTime == null) {
                this.startTime = new Date();
            }
            this.startTimer = true;
            console.log("event_list", this.event_list);
            console.log("imageMap", this.eventImageMap);
            console.log(this.site_review_map);
        },
       async getEventsMarkedInterestingCount(){
            this.startOfWeek = moment.tz(this.selectedSiteTimeZone).startOf("isoWeek").toISOString() //Monday
            this.endOfWeek = moment.tz(this.selectedSiteTimeZone).endOf("isoWeek").toISOString() //Sunday
            let user_id = getUserId();
            let response2 = await qaApiService("GET", "events/eventsMarkedAsInteresting/" + this.startOfWeek + "/" + this.endOfWeek + "/" + user_id + "/" + this.selectedSite);
            this.markedAsInteresting = response2.data  
        },
        async downloadAssets(fileName, type, site_id, region) {
            const token = await localStorage.getItem("JoT");
            const response = await axios({
                method: "GET",
                url: API_ENDPOINT_PRESIGNED_URL,
                headers: {
                    Authorization: token.replace(/['"]+/g, ""),
                },
                params: {
                    file_name: fileName,
                    type: type,
                    site_id: site_id,
                    region: region
                },
            });
            let result = await fetch(response.data.url, { method: "GET" });
            let blob = await result.blob();
            let objUrl = URL.createObjectURL(blob);
            return objUrl;
        },
        async loadEventAssets(site_id) {
            this.eventVideoMap = Object.assign({}, {});
            this.eventImageMap = Object.assign({}, {});
            for (let i = 0; i < this.event_list.length; i++) {
                let imgUrl = this.event_list[i].image_url;
                let selectedSiteRegion = this.site_review_map[
                    this.site_review_map.findIndex((x) => parseInt(x.id) === this.selectedSite)
                ].region
                console.log("region", selectedSiteRegion)
                if (
                    imgUrl != null &&
                    this.eventImageMap[imgUrl] === undefined
                ) {
                    this.eventImageMap[imgUrl] = await this.downloadAssets(
                        imgUrl,
                        "image",
                        site_id,
                        selectedSiteRegion
                    );
                    this.eventImageMap = Object.assign({}, this.eventImageMap);
                }
                let vidUrl = this.event_list[i]?.video_url;
                if (
                    vidUrl != null &&
                    this.eventVideoMap[vidUrl] === undefined
                ) {
                    this.eventVideoMap[vidUrl] = await this.downloadAssets(
                        vidUrl,
                        "video",
                        site_id,
                        selectedSiteRegion
                    );
                    this.eventVideoMap = Object.assign({}, this.eventVideoMap);
                }
                if(i==0){
                    this.loadingBatch = false
                } else if (this.event_list.length < 1){
                  this.loadingBatch = false
                }
            }
        },
        getRuleDescriptionByCamera(rules_cameras) {
            let ruleMeta = rules_cameras.find((cam_id) => {
                return cam_id?.camera_id == this.event_list[this.cursor]?.cam_id;
            });
            let description = getRuleDescription(ruleMeta.config)
            return description
        },
        async sendUpdateOnEventsInReview() {
            if (this.event_list.length >= 0) {
                let event_to_pass = this.event_list.map((data) => data.violation_uuid);
                console.log("update events list", event_to_pass)
                await qaApiService("POST", `session/updateEvents`, {
                    events: JSON.stringify(event_to_pass),
                });
            }
        },
        async sendUpdateOnUser() {
                await qaApiService("POST", `session/updateUsers`);
        },
        async flushEventSession() {
            if (this.event_list.length >= 0) {
                let event_to_pass = this.event_list.map((data) => data.violation_uuid);
                await qaApiService("POST", `session/updateEvents/flush`, {
                    events: JSON.stringify(event_to_pass),
                });
            }
        },
        async getUsersOnline() {
                this.usersOnline = await qaApiService("GET", `session/loggedInUsers`);
            },
        refetchCanvas(){
            setTimeout(()=>{
                if (this.isImgAnnotated) {
                this.undrawOnImage("img");
                this.drawOnImage("img");
            }
            if (this.isVideoAnnotatedToggle) {
                this.undrawOnImage("video");
                this.drawOnImage("video");
            }
            },100)

        }
    },
    computed: {
        ruleBlocks(){
            this.ruleBlocksList = []
            let ruleMeta =  this.event_list[this.cursor]?.rule?.rules_cameras.find((cam_id) => {
                    return cam_id?.camera_id == this.event_list[this.cursor]?.cam_id;
                });
            if(ruleMeta?.config && ruleMeta?.canvas_meta){
                for (let block of ruleMeta?.canvas_meta?.edges){
                if(!this.ruleBlocksList.includes(block["sourceInfo"]["name"])){
                    this.ruleBlocksList.push(block["sourceInfo"]["name"])
                }
            }
            return this.ruleBlocksList
            }else{
                return []
            }
        },
        getDate() {
            return this.event_list.length
                ? new Date(
                      String(this.event_list[this.cursor].event_time)
                  ).toUTCString()
                : "No Data";
        },
    },
};
</script>

<style lang="scss" scoped>
.site-block {
    background: white;
    border-radius: 6px;
    border-left: #00a0f2 6px solid;
    cursor: pointer;
    transition: all 0.3s ease;
}

.site-block:hover {
    background: #e6f3fe;
    border-radius: 6px;
    transform: scale(1.05);
}

.site-block:active {
    background: #00a0f2;
    color: white;
    border-radius: 6px;
    transform: scale(1.01);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#site-sort-dropdown.v-select.vs--open.vs--single.vs--searchable{
  width: 150px;
}
#site-sort-dropdown.v-select.vs--open.vs--single.vs--searchable>.vs__dropdown-toggle>.vs__selected-options>.vs__selected{
 margin-top: 0px;
}
#site-sort-dropdown>.vs__dropdown-toggle{
    max-height: 27px;
}
#site-sort-dropdown>.vs__dropdown-toggle>.vs__selected-options>.vs__selected{
    margin-top: -6px;
}
#video-canvas > svg > path.event-Line {
    fill: #ff695b;
    opacity: 1;
    stroke: #ff695b;
    stroke-width: 3px;
}

#video-canvas > #arrow {
    fill: #3fcb7a;
}

#video-canvas > svg > line.event-Direction {
    fill: #3fcb7a;
    opacity: 1;
    stroke: #3fcb7a;
    stroke-width: 3px;
}

#video-canvas > svg > path.event-Direction {
    fill: #3fcb7a;
    opacity: 1;
    stroke: #3fcb7a;
    stroke-width: 3px;
}

#img-canvas > svg > path.event-Line {
    fill: #ff695b;
    opacity: 1;
    stroke: #ff695b;
    stroke-width: 3px;
}

#img-canvas > #arrow {
    fill: #3fcb7a;
}

#img-canvas > svg > line.event-Direction {
    fill: #3fcb7a;
    opacity: 1;
    stroke: #3fcb7a;
    stroke-width: 3px;
}

#img-canvas > svg > path.event-Direction {
    fill: #3fcb7a;
    opacity: 1;
    stroke: #3fcb7a;
    stroke-width: 3px;
}
.center-align-video{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.event-Zone {
    fill: #00a2f2;
    stroke-opacity: 1;
    fill-opacity: 0.15;
    stroke: #00a0f3;
    stroke-dasharray: 5;
    stroke-width: 2px;
}
circle.event-Zone {
    fill: #011d2b;
    opacity: 1;
    stroke-width: 0px;
    cursor: pointer;
}

</style>
