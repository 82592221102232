const axios = require("axios");
const ENDPOINT = process.env.VUE_APP_QA_BACKEND_API;
export default async function requestApi(
    type,
    resource,
    meta,
    data
) {
  console.log("Execute API Service")
  console.log(type, resource, meta)
  const token = await localStorage.getItem("JoT");
  var response = await axios({
    method: type,
    url: ENDPOINT+resource,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token.replace(/['"]+/g, ""),
    },
    params : {
      ...meta
    },
    data: { ...data },
  });
  return response;
}
